<template>
  <surround-box specialColor="#0b3d51" background="linear-gradient(270deg, rgba(21, 101, 182, 0) 0%, #3B7AC2 50%, rgba(21, 101, 182, 0) 95%)" title="粪污预警信息">
    <div class="content-box">
      <div class="tabs">
        <p @click="handleCheck(5)" :class="idx==5?'active':''">存储量预警</p>
        <p @click="handleCheck(2)" :class="idx==2?'active':''">设备预警</p>
      </div>
      <div class="tabs_cont">
        <div v-for="(item,index) in data" :key="index">
          <img src="../../assets/imgs/warn_icon.png" />
          <p :title="item.content">{{item.content}}</p>
        </div>
      </div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from '@/components/mapComp/surroundBox4.vue'

export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      platformInfo: [],
      idx: 5,
      data: [],
    }
  },
  methods: {
    getData() {
      this.$post(this.$api.PRODUCE_MAPINFO.WARNING, {
        isAdmin: '1',
        alarmType: this.idx,
      }).then((res) => {
        this.data = res.list
      })
    },
    handleCheck(idx) {
      if (this.idx == idx) return
      this.idx = idx
      this.getData()
    },
  },
  mounted() {
    this.getData()
  },
}
</script>
<style lang='less' scoped>
.content-box {
  width: 100%;
  height: 230px;
  position: relative;
  > .tabs {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 4px;
      background: url('../../assets/imgs/line.png') no-repeat center;
      background-size: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    > p {
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }
    > .active {
      font-weight: bold;
      color: #437cb5;
    }
  }
  > .tabs_cont {
    width: 100%;
    height: 80%;
    padding: 0 0.1rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    > div {
      width: 100%;
      height: 25%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.19);
      > p {
        width: 100%;
        font-size: 14px;
        color: #fff;
        text-align: justify;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > img {
        width: 0.4rem;
        height: 0.4rem;
        margin: 0 0.05rem;
      }
    }
  }
  // .popbox {
  //   // background: radial-gradient(
  //   //   rgba(0, 255, 220, 0) 0%,
  //   //   rgba(0, 255, 220, 0.2) 70%,
  //   //   rgba(0, 255, 220, 0.7) 85%,
  //   //   rgba(0, 255, 220, 1) 100%
  //   // );
  //   background: radial-gradient(
  //     rgba(0, 255, 220, 0) 55%,
  //     rgba(0, 145, 255, 0.2) 70%,
  //     rgba(0, 145, 255, 0.7) 85%,
  //     rgba(0, 145, 255, 1) 100%
  //   );
  //   position: absolute;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex-direction: column;
  //   > span:first-child {
  //     color: #36d8ff;
  //   }
  //   span {
  //     white-space: nowrap;
  //     width: 100%;
  //     overflow: hidden;
  //     text-align: center;
  //   }
  //   > span:last-child {
  //     color: #fff;
  //   }
  // }
  // .yangzhi {
  //   left: 0.06rem;
  //   top: 0.55555vh;
  //   width: 1rem;
  //   height: 1rem;
  //   border-radius: 50px;
  // }
  // .zhongzhi {
  //   left: 1.84rem;
  //   top: 1.85185vh;
  //   width: 0.85rem;
  //   height: 0.85rem;
  //   border-radius: 45px;
  // }
  // .zuzhi {
  //   left: 2.78rem;
  //   top: 0.64814vh;
  //   width: 1.16rem;
  //   height: 1.16rem;
  //   border-radius: 70px;
  // }
  // .jisan {
  //   left: 0.06rem;
  //   top: 11.111111vh;
  //   width: 0.9rem;
  //   height: 0.9rem;
  //   border-radius: 50px;
  // }
  // .sheshi {
  //   left: 2.1rem;
  //   top: 10.74074vh;
  //   width: 0.8rem;
  //   height: 0.8rem;
  //   border-radius: 55px;
  // }
  // .siji {
  //   left: 1.1rem;
  //   top: 0.48rem;
  //   width: 0.7rem;
  //   height: 0.7rem;
  //   border-radius: 40px;
  // }
  // .yunshu {
  //   left: 1.03rem;
  //   top: 11.48148vh;
  //   width: 1rem;
  //   height: 1rem;
  //   border-radius: 50px;
  // }
  // .shebei {
  //   left: 2.98rem;
  //   top: 12.22222vh;
  //   width: 0.9rem;
  //   height: 0.9rem;
  //   border-radius: 50px;
  // }
}
</style>